import React from 'react';
import HomeTemplate from '../../components/templates/HomeTemplate';
import { Grid } from '@mui/material';
import CustomView from '../../components/organisms/CustomView';

const CustomViewPage = () => {
  return (
    <HomeTemplate>
      <Grid
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <CustomView />
      </Grid>
    </HomeTemplate>
  );
};

export default CustomViewPage;
