import React from 'react';
import HomeTemplate from '../../components/templates/HomeTemplate';
import { Grid } from '@mui/material';
import PatchTable from '../../components/organisms/PatchTable';

const PatchPage = () => {
  return (
    <HomeTemplate activeTab="Patches">
      <Grid
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <PatchTable />
      </Grid>
    </HomeTemplate>
  );
};

export default PatchPage;
