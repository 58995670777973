import { Autocomplete } from '@mui/material';
import React from 'react';
import { CustomTextField } from '../../atoms/Textfield';

interface AutoCompleteProps {
  options: string[];
  placeholder?: string;
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}

const AutoComplete = (props: AutoCompleteProps) => {
  const { options, placeholder, selectedOptions, setSelectedOptions } = props;

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string[]
  ) => {
    setSelectedOptions(newValue);
  };

  return (
    <Autocomplete
      multiple
      disableClearable
      filterSelectedOptions
      value={selectedOptions}
      onChange={(event, newValue) => handleChange(event, newValue)}
      options={options}
      getOptionLabel={(option) => option}
      sx={{ '.MuiOutlinedInput-input': { height: '13px' } }}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{ ...params.InputProps }}
        />
      )}
    />
  );
};

export default AutoComplete;
