import React, { useContext, useEffect } from 'react';
import Button from '../../atoms/Button';
import theme from '../../../theme';
import Typography from '../../atoms/Typography';
import { Grid, styled } from '@mui/material';
import {
  checkGoogleAuth,
  checkZohoAuth,
  authenticateGoogle,
  authenticateZoho,
} from '../../../services/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AuthContext } from '../../../App';
import {
  GOOGLE_CLIENT_ID,
  GOOGLE_SCOPE,
  REDIRECT_URI,
  ZOHO_CLIENT_ID,
  ZOHO_SCOPE,
} from '../../../utils/constants';
import './index.css';
import Google from '../../../assets/icons/google.png';
import Zoho from '../../../assets/icons/zoho.jpg';

const StyledGrid = styled(Grid)({
  marginTop: '20px',
  display: 'flex',
});

const StyledButton = styled(Button)({
  width: '300px',
  height: '50px',
  margin: '10px',
});

const Login = () => {
  const { googleAuth, setGoogleAuth, zohoAuth, setZohoAuth } =
    useContext(AuthContext);
  const location = useLocation();
  const { search } = location;
  const navigate = useNavigate();

  const handleGoogleAuth = () => {
    if (!googleAuth) {
      const url = `https://accounts.google.com/o/oauth2/v2/auth?scope=${GOOGLE_SCOPE}&client_id=${GOOGLE_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&access_type=offline&prompt=consent`;
      window.open(url, '_self');
    }
  };

  const handleZohoAuth = () => {
    if (!zohoAuth) {
      const url = `https://accounts.zoho.com/oauth/v2/auth?scope=${ZOHO_SCOPE}&client_id=${ZOHO_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&access_type=offline&prompt=consent`;
      window.open(url, '_self');
    }
  };

  const getToken = async () => {
    if (search.includes('google')) {
      const response = await authenticateGoogle(search);
      setGoogleAuth(response);
    } else if (search.includes('zoho')) {
      const response = await authenticateZoho(search);
      setZohoAuth(response);
    }
    navigate('/');
  };

  useEffect(() => {
    if (search) {
      getToken();
    }
  }, [search]);

  const checkAuth = async () => {
    const res1 = await checkGoogleAuth();
    setGoogleAuth(res1);
    const res2 = await checkZohoAuth();
    setZohoAuth(res2);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Grid
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid>
        <Typography
          label="Z-GUEMSync"
          variant="h4"
          color={theme.palette.grey.A200}
          className="focus-in-expand"
        />
      </Grid>
      <Grid>
        <StyledGrid container spacing={2} justifyContent="center">
          <StyledButton
            className="scale-in-hor-center"
            variant="contained"
            backgroundColor={theme.palette.primaryColor[500]}
            startIcon={<img src={Google} alt="Google Icon" width="22" />}
            onClick={handleGoogleAuth}
            endIcon={
              googleAuth && (
                <CheckCircleIcon
                  sx={{ color: theme.palette.successColor[500] }}
                />
              )
            }
          >
            <Typography
              label="Connect to Google"
              variant="subtitle1"
              color={theme.palette.grey.A100}
            />
          </StyledButton>

          <StyledButton
            className="scale-in-hor-center"
            variant="contained"
            backgroundColor={theme.palette.primaryColor[500]}
            startIcon={<img src={Zoho} alt="Zoho Icon" width="22" />}
            onClick={handleZohoAuth}
            endIcon={
              zohoAuth && (
                <CheckCircleIcon
                  sx={{ color: theme.palette.successColor[500] }}
                />
              )
            }
          >
            <Typography
              label="Connect to Zoho"
              variant="subtitle1"
              color={theme.palette.grey.A100}
            />
          </StyledButton>
        </StyledGrid>
      </Grid>
      <StyledButton
        className="scale-in-hor-center"
        variant="contained"
        backgroundColor={theme.palette.successColor[500]}
        onClick={() => navigate('/home')}
        disabled={!zohoAuth && !googleAuth}
      >
        <Typography
          label="Continue"
          variant="subtitle1"
          color={theme.palette.grey.A100}
        />
      </StyledButton>
    </Grid>
  );
};

export default Login;
