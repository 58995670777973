import { CircularProgress, Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import { DialogContext, LoadingContext, ViewsContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { getViews } from '../../../services/uem';
import Button from '../../atoms/Button';

const ViewList = () => {
  const { views, setViews } = useContext(ViewsContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setDialog } = useContext(DialogContext);

  const navigate = useNavigate();

  const getData = async () => {
    setIsLoading(true);
    const tempViews = await getViews();
    if (tempViews) {
      setViews(tempViews);
    } else {
      setDialog({
        title: 'API Error',
        message: 'An error occured while loading Views',
        error: true,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleViewEdit = (id: string) => {
    if (id) {
      navigate(`/views/${id}/edit`);
    }
  };

  const handleViewClick = (id: string) => {
    if (id) {
      navigate(`/views/${id}`);
    }
  };

  const handleCreateView = () => {
    navigate('/views/new');
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {isLoading ? (
        <Grid
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          sx={{
            display: 'flex',
            width: '80%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '15px',
            paddingBottom: '40px',
          }}
        >
          <Grid
            sx={{
              width: '100%',
              height: '100%',
              gap: '5px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingBottom: '20px',
                }}
              >
                <Typography
                  label="Views"
                  variant="h6"
                  color={theme.palette.primaryColor[500]}
                  sx={{ flexGrow: 1 }}
                />
                <Button
                  variant="contained"
                  onClick={handleCreateView}
                  backgroundColor={theme.palette.grey.A100}
                  borderColor={theme.palette.grey[200]}
                  boxShadowColor={theme.palette.primaryColor[200]}
                >
                  <Typography
                    label="Create View"
                    variant="body1"
                    color={theme.palette.text.high}
                  />
                </Button>
              </Grid>
              <Grid
                sx={{
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: theme.spacing(4, 6),
                }}
              >
                <Grid
                  container
                  width="57.5%"
                  sx={{
                    textAlign: 'left',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    label="View Name"
                    variant="subtitle2"
                    color={theme.palette.text.high}
                  />
                </Grid>
                <Grid
                  container
                  width="20%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    label="Hosts"
                    variant="subtitle2"
                    color={theme.palette.text.high}
                  />
                </Grid>
                <Grid
                  container
                  width="22.5%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    label="Action"
                    variant="subtitle2"
                    color={theme.palette.text.high}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  height: '100%',
                }}
              >
                {views.length > 0 ? (
                  <>
                    {views.map((view) => {
                      return (
                        <Grid
                          key={view._id}
                          container
                          width="100%"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: theme.spacing(4, 6),
                            // borderRadius: '4px',
                            borderBottom: '1px solid #E8E8F7',
                          }}
                        >
                          <Grid
                            item
                            width="60%"
                            onClick={() => handleViewClick(view._id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Typography
                              label={view.name}
                              variant="body1"
                              color={theme.palette.text.high}
                            />
                          </Grid>
                          <Grid
                            item
                            width="20%"
                            display="flex"
                            justifyContent="center"
                          >
                            <Typography
                              label={view?.hosts?.length.toString() ?? '-'}
                              variant="body1"
                              color={theme.palette.text.high}
                            />
                          </Grid>
                          <Grid
                            item
                            width="20%"
                            display="flex"
                            justifyContent="center"
                          >
                            <Button
                              variant="contained"
                              onClick={() => handleViewEdit(view._id)}
                              backgroundColor={theme.palette.grey.A100}
                              borderColor={theme.palette.grey[200]}
                              boxShadowColor={theme.palette.errorColor[500]}
                            >
                              <Typography
                                label="Edit"
                                variant="body1"
                                color={theme.palette.text.high}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ViewList;
