import { CircularProgress, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import Textfield from '../../atoms/Textfield';
import AutoComplete from '../../molecules/AutoComplete';
import { DialogContext, EmployeesContext, LoadingContext } from '../../../App';
import {
  getEmployeeData,
  getView,
  saveView,
  updateView,
} from '../../../services/uem';
import Button from '../../atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';

const CustomViewForm = () => {
  const [viewName, setViewName] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const { employees, setEmployees } = useContext(EmployeesContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setDialog } = useContext(DialogContext);

  const navigate = useNavigate();
  const viewId = useParams()?.viewId;

  const getData = async () => {
    setIsLoading(true);
    const emp = await getEmployeeData();
    if (emp) {
      setEmployees(emp);
    } else {
      setDialog({
        title: 'API Error',
        message: 'An error occured while loading Employees',
        error: true,
      });
    }
    if (viewId) {
      const tempView = await getView(viewId);
      if (tempView && tempView._id) {
        setViewName(tempView.name);
        setSelectedOptions(tempView.hosts ?? []);
      } else {
        setDialog({
          title: 'API Error',
          message: 'An error occured while loading the View',
          error: true,
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const hostNames = new Set<string>();
    employees.forEach((emp) => {
      if (emp?.serialNumber) {
        hostNames.add(emp.serialNumber);
      }
    });
    setOptions(Array.from(hostNames));
  }, [employees]);

  const handleViewNameChange = (e: {
    target: { name: any; value: string };
  }) => {
    setViewName(e.target.value);
  };

  const createView = async () => {
    if (viewName.trim().length > 0 && selectedOptions.length > 0) {
      let view;
      if (viewId) {
        view = await updateView(viewId, viewName, selectedOptions);
      } else {
        view = await saveView(viewName, selectedOptions);
      }
      if (view && view._id) {
        navigate('/views/' + view._id);
      } else {
        setDialog({
          title: 'API Error',
          message: `An error occured while ${
            viewId ? 'updating' : 'creating'
          } View`,
          error: true,
        });
      }
    }
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {isLoading ? (
        <Grid
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          sx={{
            display: 'flex',
            width: '50%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <Grid>
            <Typography
              label="Custom View Form"
              variant="h6"
              color={theme.palette.primaryColor[500]}
            />
          </Grid>
          <Grid
            sx={{
              width: '100%',
              gap: '5px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                label="View Name"
                variant="body1"
                color={theme.palette.text.high}
              />
              <Textfield
                variant="outlined"
                placeHolderContent="Enter View Name"
                inputValue={viewName}
                onChangeMethod={handleViewNameChange}
              />
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                label="Hosts"
                variant="body1"
                color={theme.palette.text.high}
              />
              <AutoComplete
                options={options}
                placeholder="Add Hosts"
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={createView}
            disabled={
              !(viewName.trim().length > 0 && selectedOptions.length > 0)
            }
            backgroundColor={theme.palette.grey.A100}
            borderColor={theme.palette.grey[200]}
            boxShadowColor={theme.palette.primaryColor[200]}
          >
            <Typography
              label={viewId ? 'Update View' : 'Create View'}
              variant="body1"
              color={theme.palette.text.high}
            />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomViewForm;
