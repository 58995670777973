import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
  ColDef,
  ColGroupDef,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  SizeColumnsToContentStrategy,
} from '@ag-grid-community/core';
import { Employee } from '../../../utils/types';
import { Grid } from '@mui/material';
import Button from '../../atoms/Button';
import theme from '../../../theme';
import Typography from '../../atoms/Typography';
import { exportDataToSheets } from '../../../services/uem';
import { DialogContext, LoadingContext } from '../../../App';
import DateTimeFilter from './DateTimeFilter';
import { AgGridReact } from '@ag-grid-community/react';

interface EmployeeTableProps {
  employees: Employee[];
}

const EmployeesTable = (props: EmployeeTableProps) => {
  const { employees } = props;
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setDialog } = useContext(DialogContext);
  const [dateTimeFilterText, setDateTimeFilterText] = useState<string>('');

  const handleDateTimeFilterTextChange = (value: string) => {
    console.log(value);
    setDateTimeFilterText(value);
  };

  const getDateTimeFilterText = () => {
    return dateTimeFilterText;
  };

  const gridRef = useRef<AgGridReact>(null);

  const [columns, setColumns] = useState<
    (ColDef | ColGroupDef)[] | null | undefined
  >([]);
  const defaultColDef = useMemo(() => ({ filter: true }), []);

  const getPatchCount = (value: string) => {
    if (value?.length > 0) {
      if (!isNaN(value as any)) {
        return Number(value);
      } else {
        return Number(-1);
      }
    } else {
      return null;
    }
  };

  const initializeColumns = () => {
    const tempColumns: (ColDef | ColGroupDef)[] = [
      {
        field: 'employeeId',
        headerName: 'Employee ID',
        valueGetter: (params) => params.data?.empId ?? null,
        pinned: 'left',
        width: 150,
        suppressMovable: true,
        lockPosition: 'left',
      },
      {
        headerName: 'Employee Details',
        children: [
          {
            field: 'employeeEmail',
            headerName: 'Email',
            valueGetter: (params) => params.data?.email ?? null,
          },
          {
            field: 'employeeName',
            headerName: 'Name',
            valueGetter: (params) =>
              `${params.data.firstName || ''} ${params.data.lastName || ''}`,
            // columnGroupShow: 'open',
          },
          {
            field: 'employeeDepartment',
            headerName: 'Department',
            valueGetter: (params) => params.data?.department ?? null,
            columnGroupShow: 'open',
          },
          {
            field: 'employeeDesignation',
            headerName: 'Designation',
            valueGetter: (params) => params.data?.designation ?? null,
            columnGroupShow: 'open',
          },
          {
            field: 'employeeReportingManagerEmail',
            headerName: 'Reporting Manager',
            valueGetter: (params) => params.data?.managerEmail ?? null,
          },
          {
            field: 'employeeType',
            headerName: 'Employee Type',
            valueGetter: (params) => params.data?.employeeType ?? null,
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'PC Details',
        children: [
          {
            field: 'serialNumber',
            headerName: 'Host Name',
            valueGetter: (params) => params.data?.serialNumber ?? null,
          },
          {
            field: 'computerId',
            headerName: 'Computer ID',
            valueGetter: (params) => params.data?.computerId ?? null,
            columnGroupShow: 'open',
          },
          {
            field: 'userName',
            headerName: 'User Name',
            valueGetter: (params) => params.data?.userName ?? null,
          },
          {
            field: 'osPlatformName',
            headerName: 'OS PLatform Name',
            valueGetter: (params) => params.data?.osPlatformName ?? null,
            columnGroupShow: 'open',
          },
          {
            field: 'osName',
            headerName: 'OS Name',
            valueGetter: (params) => params.data?.osName ?? null,
            columnGroupShow: 'open',
          },
          {
            field: 'computerLiveStatus',
            headerName: 'Computer Live Status',
            valueGetter: (params) => {
              if (params.data?.computerLiveStatus === 1) return 'Live';
              else if (params.data?.computerLiveStatus === 2) return 'Down';
              else if (params.data?.computerLiveStatus === 3) return 'Unknown';
              else return null;
            },
            // columnGroupShow: 'open',
          },
          {
            field: 'lastBootupTime',
            headerName: 'Last Bootup Time',
            valueGetter: (params) => {
              const date = new Date(parseInt(params.data?.lastBootupTime));
              return !isNaN(date.getTime()) ? date.toLocaleString() : 'NA';
            },
            filter: DateTimeFilter,
            // type: ,
          },
          {
            field: 'ownerEmail',
            headerName: 'Owner Email',
            valueGetter: (params) => params.data?.ownerEmail ?? null,
            columnGroupShow: 'open',
          },
        ],
      },
      // {
      //   headerName: 'Softwares',
      //   children: [
      //     {
      //       field: 'wazuh',
      //       headerName: 'Wazuh',
      //       valueGetter: (params) => params.data?.wazuh ?? null,
      //     },
      //     {
      //       field: 'endpoint',
      //       headerName: 'Endpoint Protector',
      //       valueGetter: (params) => params.data?.endpoint ?? null,
      //       columnGroupShow: 'open',
      //     },
      //     {
      //       field: 'crowdStrike',
      //       headerName: 'CrowdStrike Falcon',
      //       valueGetter: (params) => params.data?.crowdStrike ?? null,
      //       columnGroupShow: 'open',
      //     },
      //   ],
      // },
      // {
      //   headerName: 'Patch Details',
      //   children: [
      //     {
      //       field: 'lowPatch',
      //       headerName: 'Low Patch',
      //       valueGetter: (params) => getPatchCount(params.data?.low),
      //       cellDataType: 'number',
      //       columnGroupShow: 'open',
      //     },
      //     {
      //       field: 'moderatePatch',
      //       headerName: 'Moderate Patch',
      //       valueGetter: (params) => getPatchCount(params.data?.moderate),
      //       cellDataType: 'number',
      //       columnGroupShow: 'open',
      //     },
      //     {
      //       field: 'importantPatch',
      //       headerName: 'Important Patch',
      //       valueGetter: (params) => getPatchCount(params.data?.important),
      //       cellDataType: 'number',
      //       columnGroupShow: 'open',
      //     },
      //     {
      //       field: 'criticalPatch',
      //       headerName: 'Critical Patch',
      //       valueGetter: (params) => getPatchCount(params.data?.critical),
      //       cellDataType: 'number',
      //     },
      //     {
      //       field: 'deploymentFailed',
      //       headerName: 'Deployment Failed',
      //       valueGetter: (params) => params.data?.deploymentFailed,
      //       valueFormatter: (params) => params?.value,
      //       columnGroupShow: 'open',
      //     },
      //     {
      //       field: 'rebootRequired',
      //       headerName: 'Reboot Required',
      //       valueGetter: (params) => params.data?.rebootRequired,
      //       columnGroupShow: 'open',
      //     },
      //   ],
      //   // flex: 1,
      // },
    ];

    // const newColumns = new Set<string>();
    // employees.forEach((emp) => {
    //   emp.softwares?.forEach((sw) => {
    //     newColumns.add(sw.name);
    //   });
    // });
    // const additionalColumns: (ColDef | ColGroupDef)[] = [];
    // const newColumnsArray = Array.from(newColumns);
    // newColumnsArray.forEach((col, index) => {
    //   additionalColumns.push({
    //     field: col,
    //     headerName: col,
    //     valueGetter: (params) => {
    //       const index = params.data?.softwares?.findIndex(
    //         (s: any) => s.name === col
    //       );
    //       return index !== -1 ? params.data?.softwares[index]?.version : null;
    //     },
    //     columnGroupShow: index === 0 ? undefined : 'open',
    //   });
    // });
    // if (additionalColumns.length > 0) {
    //   tempColumns.push({
    //     headerName: 'Additional Softwares',
    //     children: additionalColumns,
    //   });
    // }
    setColumns(tempColumns);
  };

  useEffect(() => {
    initializeColumns();
  }, [employees]);

  const autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
    type: 'fitGridWidth',
  };

  const exportData = useCallback(() => {
    gridRef.current?.api.exportDataAsCsv();
  }, []);

  const exportToSheets = async () => {
    setIsLoading(true);
    const url = await exportDataToSheets(employees);
    if (url) {
      setDialog({
        title: 'Export Successful',
        message: 'Employee data is exported to the Google Sheet.',
        url: url,
        error: false,
      });
    } else {
      setDialog({
        title: 'Export Failure',
        message:
          'An error occured while exporting employee data to Google Sheet',
        error: true,
      });
    }
    setIsLoading(false);
  };

  const removeFilters = () => {
    gridRef.current?.api.setFilterModel(null);
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '10px',
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          width: '100%',
          gap: '5px',
        }}
      >
        <Grid sx={{ display: 'flex', flexGrow: 1 }} />
        <Button
          variant="contained"
          onClick={removeFilters}
          backgroundColor={theme.palette.grey.A100}
          borderColor={theme.palette.grey[200]}
          boxShadowColor={theme.palette.primaryColor[200]}
        >
          <Typography
            label="Remove Filters"
            variant="body1"
            color={theme.palette.text.high}
          />
        </Button>
        <Button
          variant="contained"
          onClick={exportToSheets}
          backgroundColor={theme.palette.grey.A100}
          borderColor={theme.palette.grey[200]}
          boxShadowColor={theme.palette.primaryColor[200]}
        >
          <Typography
            label="Export as Sheets"
            variant="body1"
            color={theme.palette.text.high}
          />
        </Button>
        <Button
          variant="contained"
          onClick={exportData}
          backgroundColor={theme.palette.grey.A100}
          borderColor={theme.palette.grey[200]}
          boxShadowColor={theme.palette.primaryColor[200]}
        >
          <Typography
            label="Export as CSV"
            variant="body1"
            color={theme.palette.text.high}
          />
        </Button>
      </Grid>
      <Grid
        className="ag-theme-quartz"
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={employees}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          autoSizeStrategy={autoSizeStrategy}
          pagination
          enableCellTextSelection
          reactiveCustomComponents
        />
      </Grid>
    </Grid>
  );
};

export default EmployeesTable;
