import React from 'react';
import HomeTemplate from '../../components/templates/HomeTemplate';
import { Grid } from '@mui/material';
import ViewList from '../../components/organisms/ViewList';

const ViewListPage = () => {
  return (
    <HomeTemplate activeTab="Views">
      <Grid
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <ViewList />
      </Grid>
    </HomeTemplate>
  );
};

export default ViewListPage;
