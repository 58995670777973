import React from 'react';
import HomeTemplate from '../../components/templates/HomeTemplate';
import { Grid } from '@mui/material';
import CustomViewForm from '../../components/organisms/CustomViewForm';

const CustomViewFormPage = () => {
  return (
    <HomeTemplate>
      <Grid
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <CustomViewForm />
      </Grid>
    </HomeTemplate>
  );
};

export default CustomViewFormPage;
