import axios from 'axios';
import get from 'lodash/get';
import { getGoogleAccessToken, getZohoAccessToken } from './auth';
import { Employee, Patch, View } from '../utils/types';
import { BASE_API_URL, LAST_SYNC } from '../utils/constants';

export const getEmployeeData = async () => {
  let error = false;
  let employees: Employee[] = [];
  let result1: any[] = [];
  let result2: any[] = [];
  await axios
    .get(`${BASE_API_URL}/employees`)
    .then((response) => {
      result1 = response.data;
    })
    .catch((err) => {
      error = true;
    });
  await axios
    .get(`${BASE_API_URL}/computers`)
    .then((response) => {
      result2 = response.data;
    })
    .catch((err) => {
      error = true;
    });
  result2.forEach((comp) => {
    const empIndex = result1.findIndex((e) => e?.email === comp?.ownerEmail);
    if (empIndex !== -1) {
      employees.push({ ...result1[empIndex], ...comp });
    } else {
      employees.push({ ...comp });
    }
  });
  result1.forEach((emp) => {
    const empIndex = employees.findIndex((e) => e?.email === emp?.email);
    if (empIndex === -1) {
      employees.push({ ...emp });
    }
  });
  if (error) {
    return null;
  } else {
    return employees;
  }
};

export const syncEmployeeAndComputerData = async () => {
  let error = false;
  let employees: Employee[] = [];
  let result1: any[] = [];
  let result2: any[] = [];
  const googleAuth = getGoogleAccessToken();
  const zohoAuth = getZohoAccessToken();
  if (googleAuth && zohoAuth) {
    await axios
      .get(`${BASE_API_URL}/sync/employees`, {
        headers: { 'google-auth': `Bearer ${googleAuth.access_token}` },
      })
      .then((response) => {
        result1 = response.data;
      })
      .catch((err) => {
        error = true;
      });
    await axios
      .get(`${BASE_API_URL}/sync/computers`, {
        headers: { 'zoho-auth': `Bearer ${zohoAuth.access_token}` },
      })
      .then((response) => {
        result2 = response.data;
      })
      .catch((err) => {
        error = true;
      });
    result2.forEach((comp) => {
      const empIndex = result1.findIndex((e) => e?.email === comp?.ownerEmail);
      if (empIndex !== -1) {
        employees.push({ ...result1[empIndex], ...comp });
      } else {
        employees.push({ ...comp });
      }
    });
    result1.forEach((emp) => {
      const empIndex = employees.findIndex((e) => e?.email === emp?.email);
      if (empIndex === -1) {
        employees.push({ ...emp });
      }
    });
    if (error) {
      return null;
    } else {
      localStorage.setItem(LAST_SYNC, Date.now().toString());
      return employees;
    }
  } else {
    return null;
  }
};

// export const syncDataIntoDB = async () => {
//   const googleAuth = getGoogleAccessToken();
//   const zohoAuth = getZohoAccessToken();
//   let error = false;
//   let employees: Employee[] = [];
//   await axios
//     .get(`${BASE_API_URL}/sync/employee-data`, {
//       headers: {
//         'zoho-auth': `Bearer ${zohoAuth.access_token}`,
//         'google-auth': `Bearer ${googleAuth.access_token}`,
//       },
//     })
//     .then((res) => {
//       employees = res.data;
//     })
//     .catch((err) => {
//       console.log('Error while syncing data');
//       console.log(err);
//       error = true;
//     });
//   if (error) {
//     return null;
//   } else {
//     localStorage.setItem(LAST_SYNC, Date.now().toString());
//     return employees;
//   }
// };

export const exportDataToSheets = async (employees: Employee[]) => {
  let spreadsheetUrl = '';
  if (employees.length > 0) {
    const googleAuth = getGoogleAccessToken();
    const employeesArray = convertToArray(employees);

    const sheetData = employeesArray.map((employee, index) => {
      const rowData = Object.values(employee).map((value) => {
        const x = {
          userEnteredValue: {
            stringValue: value?.toString() ?? '',
          },
        };
        return x;
      });
      const row = {
        startRow: index,
        startColumn: 0,
        rowData: [
          {
            values: rowData,
          },
        ],
      };
      return row;
    });

    const sheetObj = {
      properties: { title: `GWS-UEM ${new Date().toLocaleString()}` },
      sheets: [
        {
          properties: { title: 'Employees' },
          data: sheetData,
        },
      ],
    };

    const response = await axios
      .post('https://sheets.googleapis.com/v4/spreadsheets', sheetObj, {
        headers: { Authorization: `Bearer ${googleAuth.access_token}` },
      })
      .catch((err: any) => {
        console.log('Error while exporting into Google Sheets: ');
        console.log(err);
      });
    if (response?.data) {
      spreadsheetUrl = response.data?.spreadsheetUrl ?? '';
      console.log(spreadsheetUrl);
    }
  }
  return spreadsheetUrl;
};

const convertToArray = (employees: Employee[]) => {
  const finalArray = [];
  const headings = getKeys(employees[0]);
  finalArray.push(headings);

  for (const employee of employees) {
    const values: string[] = [];
    headings.forEach((key) => values.push(get(employee, key, '')));
    finalArray.push(values);
  }
  return finalArray;
};

const getKeys = (object: Object) => {
  const result: string[] = [];
  const iterationFunction = (iterationObject: any, path: string[]) => {
    if (Array.isArray(iterationObject)) {
      return;
    }
    if (iterationObject && typeof iterationObject === 'object') {
      const keys = Object.keys(iterationObject);
      if (keys.length) {
        keys.forEach((k) => {
          iterationFunction(iterationObject[k], path.concat(k));
        });
      }
      return;
    }
    result.push(path.join('.'));
  };
  iterationFunction(object, []);
  return result;
};

export const getSyncTime = () => {
  const lastSyncString = localStorage.getItem(LAST_SYNC);
  return lastSyncString ?? null;
};

export const getNewSoftware = async (software: string) => {
  const zohoAuth = getZohoAccessToken();
  let error = false;
  let employees: Employee[] = [];
  await axios
    .get(`${BASE_API_URL}/sync/installed-softwares?name=${software}`, {
      headers: { 'zoho-auth': `Bearer ${zohoAuth.access_token}` },
    })
    .then((res) => {
      employees = res.data;
    })
    .catch((err) => {
      console.log('Error while getting data related to new Software');
      console.log(err);
      error = true;
    });
  if (error) {
    return null;
  } else {
    return employees;
  }
};

export const getPatchData = async () => {
  let error = false;
  let patches: Patch[] = [];
  await axios
    .get(`${BASE_API_URL}/patches/missing-unapproved-patches`)
    .then((response) => {
      patches = response.data;
    })
    .catch((err) => {
      error = true;
    });
  if (error) {
    return null;
  } else {
    return patches;
  }
};

export const getViews = async () => {
  let error = false;
  let views: View[] = [];
  await axios
    .get(`${BASE_API_URL}/views`)
    .then((response) => {
      views = response.data;
    })
    .catch((err) => {
      error = true;
    });
  if (error) {
    return null;
  } else {
    return views;
  }
};

export const saveView = async (name: string, hostIds: string[]) => {
  let error = false;
  let view: View | null = {} as View;
  await axios
    .post(`${BASE_API_URL}/views`, { name: name, hostIds: hostIds })
    .then((response) => {
      view = response.data;
    })
    .catch((err) => {
      error = true;
    });
  if (error) {
    return null;
  } else {
    return view;
  }
};

export const getView = async (id: string) => {
  let error = false;
  let view: View = {} as View;
  await axios
    .get(`${BASE_API_URL}/views/${id}`)
    .then((response) => {
      view = response.data;
    })
    .catch((err) => {
      error = true;
    });
  if (error) {
    return null;
  } else {
    return view;
  }
};

export const updateView = async (
  id: string,
  name: string,
  hostIds: string[]
) => {
  let error = false;
  let view: View | null = {} as View;
  await axios
    .post(`${BASE_API_URL}/views/${id}`, { name: name, hostIds: hostIds })
    .then((response) => {
      view = response.data;
    })
    .catch((err) => {
      error = true;
    });
  if (error) {
    return null;
  } else {
    return view;
  }
};

export const deleteView = async (id: string) => {
  let error = false;
  await axios.delete(`${BASE_API_URL}/views/${id}`).catch((err) => {
    error = true;
  });
  if (error) {
    return null;
  } else {
    return true;
  }
};
