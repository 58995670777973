import React, { useContext, useEffect, useState } from 'react';
import {
  DialogContext,
  EmployeesContext,
  LoadingContext,
  ViewsContext,
} from '../../../App';
import { deleteView, getEmployeeData, getViews } from '../../../services/uem';
import { CircularProgress, Grid } from '@mui/material';
import EmployeesTable from '../EmployeesTable';
import { useNavigate, useParams } from 'react-router-dom';
import { Employee, View } from '../../../utils/types';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import Button from '../../atoms/Button';

const CustomView = () => {
  const { setEmployees } = useContext(EmployeesContext);
  const { setViews } = useContext(ViewsContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setDialog } = useContext(DialogContext);

  const [viewEmployees, setViewEmployees] = useState<Employee[]>([]);
  const [view, setView] = useState<View>();

  const viewId = useParams()?.viewId;
  const navigate = useNavigate();

  const getData = async () => {
    setIsLoading(true);
    const emp = await getEmployeeData();
    const tempViews = await getViews();
    if (emp && tempViews) {
      setEmployees(emp);
      setViews(tempViews);
      filterData(emp, tempViews);
    } else {
      setDialog({
        title: 'API Error',
        message: 'An error occured while loading Employees',
        error: true,
      });
    }
    setIsLoading(false);
  };

  const filterData = (tempEmployees: Employee[], tempViews: View[]) => {
    if (
      tempEmployees.length > 0 &&
      tempViews.length > 0 &&
      viewId &&
      viewId?.length > 0
    ) {
      const viewIndex = tempViews.findIndex((view) => view._id === viewId);
      if (viewIndex !== -1) {
        setView(tempViews[viewIndex]);
        const filteredEmployees: Employee[] = [];
        tempEmployees.forEach((e) => {
          if (
            tempViews[viewIndex].hosts &&
            e.serialNumber &&
            tempViews[viewIndex].hosts?.includes(e?.serialNumber)
          ) {
            filteredEmployees.push(e);
          }
          return false;
        });
        setViewEmployees(filteredEmployees);
      } else {
        setDialog({
          title: 'View Error',
          message: 'We could not find view with this ID',
          error: true,
        });
      }
    }
  };

  useEffect(() => {
    getData();
  }, [viewId]);

  const handleDeleteView = async () => {
    if (viewId) {
      setIsLoading(true);
      const status = await deleteView(viewId);
      if (status) {
        navigate('/views');
      } else {
        setDialog({
          title: 'API Error',
          message: 'An error occured while deleting the view',
          error: true,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {isLoading ? (
        <Grid
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            height: '100%',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              label={view?.name ?? ''}
              variant="subtitle1"
              color={theme.palette.primaryColor[500]}
              sx={{ flexGrow: 1 }}
            />
            <Button
              variant="contained"
              onClick={handleDeleteView}
              backgroundColor={theme.palette.grey.A100}
              borderColor={theme.palette.grey[200]}
              boxShadowColor={theme.palette.errorColor[500]}
            >
              <Typography
                label="Delete"
                variant="body1"
                color={theme.palette.text.high}
              />
            </Button>
          </Grid>
          {viewEmployees && viewEmployees.length > 0 ? (
            <EmployeesTable employees={viewEmployees} />
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default CustomView;
