import React from 'react';
import { TextField, styled } from '@mui/material';
import theme from '../../../theme';

interface TextFieldProps {
  variant: 'standard' | 'filled' | 'outlined';
  width?: string;
  height?: string;
  placeHolderContent?: string;
  onChangeMethod?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue?: string | number | (string | number)[];
  error?: boolean;
  helperText?: string;
  type?: string;
  name?: string;
}

export const CustomTextField = styled(TextField)({
  backgroundColor: theme.palette.grey.A100,
  alignItems: 'center',
  borderRadius: '4px',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  gap: '0px',
  display: 'flex',
  '& .MuiOutlinedInput-root': {
    height: '100%',
    width: '100%',
    fieldset: {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    '&:Mui-focused, &.fieldset': {
      border: `1px solid ${theme.palette.primaryColor[500]}`,
    },
  },
  '& .MuiInputBase-root input': {
    padding: '9px 21px 9px 12px',
    color: theme.palette.text.high,
    '&::placeholder': {
      //   Typography: `${theme.typography.body2}`,
      color: `${theme.palette.text.medium}`,
    },
  },
});

const Textfield = (props: TextFieldProps) => {
  return (
    <CustomTextField
      variant={props.variant}
      data-testid="text-field"
      placeholder={props.placeHolderContent}
      onChange={props.onChangeMethod}
      value={props.inputValue}
      error={props.error}
      name={props.name}
      helperText={props.helperText}
      type={props.type}
      sx={{ width: props.width, height: props.height }}
    />
  );
};

export default Textfield;
