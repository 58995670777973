import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ColDef,
  ColGroupDef,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  SizeColumnsToContentStrategy,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { CircularProgress, Grid } from '@mui/material';
import { Patch } from '../../../utils/types';
import { DialogContext, LoadingContext } from '../../../App';
import { getPatchData } from '../../../services/uem';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import theme from '../../../theme';

const PatchTable = () => {
  const gridRef = useRef<AgGridReact>(null);
  const defaultColDef = useMemo(() => ({ filter: true }), []);

  const [columns, setColumns] = useState<(ColDef | ColGroupDef)[] | null>([]);
  const [patches, setPatches] = useState<Patch[]>([]);

  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setDialog } = useContext(DialogContext);

  const initializeColumns = () => {
    const tempColumns: (ColDef | ColGroupDef)[] = [
      {
        field: 'patchId',
        headerName: 'Patch ID',
        valueGetter: (params) => params.data?.patchId ?? null,
        pinned: 'left',
        width: 150,
        suppressMovable: true,
        lockPosition: 'left',
      },
      {
        field: 'bulletinId',
        headerName: 'Bulletin ID',
        valueGetter: (params) => params.data?.bulletinId ?? null,
      },
      {
        field: 'patchName',
        headerName: 'Patch Name',
        valueGetter: (params) => params.data?.patchName ?? null,
      },
      {
        field: 'patchDescription',
        headerName: 'Patch Description',
        valueGetter: (params) => params.data?.patchDescription ?? null,
      },
      {
        field: 'vendorName',
        headerName: 'Vendor Name',
        valueGetter: (params) => params.data?.vendorName ?? null,
      },
      {
        field: 'severityName',
        headerName: 'Severity Name',
        valueGetter: (params) => params.data?.severityName ?? null,
      },
      {
        field: 'missingSystems',
        headerName: 'Missing Systems',
        valueGetter: (params) => params.data?.missingSystems ?? null,
      },
      {
        field: 'platformName',
        headerName: 'Platform Name',
        valueGetter: (params) => params.data?.platformName ?? null,
      },
      {
        field: 'isRebootRequired',
        headerName: 'Is Reboot Required?',
        valueGetter: (params) => params.data?.isRebootRequired ?? null,
      },
      {
        field: 'patchReleasedTime',
        headerName: 'Patch Released Time',
        valueGetter: (params) => {
          return params.data?.patchReleasedTime
            ? new Date(params.data.patchReleasedTime)
            : new Date('');
        },
      },
    ];
    setColumns(tempColumns);
  };

  const getData = async () => {
    setIsLoading(true);
    const tempPatches = await getPatchData();
    if (tempPatches) {
      setPatches(tempPatches);
    } else {
      setDialog({
        title: 'API Error',
        message: 'An error occured while loading Patches',
        error: true,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    initializeColumns();
  }, [patches]);

  const exportData = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  const autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
    type: 'fitGridWidth',
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {isLoading ? (
        <Grid
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            gap: '10px',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Grid sx={{ display: 'flex', flexGrow: 1 }} />
            <Button
              variant="contained"
              onClick={exportData}
              backgroundColor={theme.palette.primaryColor[500]}
            >
              <Typography
                label="Export Data"
                variant="body1"
                color={theme.palette.grey.A100}
              />
            </Button>
          </Grid>
          <div
            className="ag-theme-quartz"
            style={{ height: '100%', flexGrow: 1 }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={patches}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              autoSizeStrategy={autoSizeStrategy}
              pagination
              enableCellTextSelection
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default PatchTable;
