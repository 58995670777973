import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  ModuleRegistry,
} from '@ag-grid-community/core';
import { CustomFilterProps, useGridFilter } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import Button from '../../atoms/Button';
import theme from '../../../theme';
import Typography from '../../atoms/Typography';
import Textfield from '../../atoms/Textfield';
import { DateTimeField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Grid } from '@mui/material';
import { CsvExportModule } from '@ag-grid-community/csv-export';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const DateTimeFilter = ({
  model,
  onModelChange,
  getValue,
}: CustomFilterProps) => {
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  // const checkIfDateBelongsInRange = (value) => {};

  useEffect(() => {
    console.log(model);
  }, [model]);

  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { data } = params;
      const value = data?.lastBootupTime;
      // console.log(fromDate?.getTime());
      // console.log(toDate?.getTime());
      // console.log(value);
      // console.log(fromDate);
      // console.log(toDate);
      if (fromDate && toDate) {
        return value >= fromDate.getTime() && value <= toDate.getTime();
      } else if (fromDate && !toDate) {
        return value >= fromDate.getTime();
      } else if (!fromDate && toDate) {
        return value <= toDate.getTime();
      }
      return true;
    },
    [model]
  );

  // const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
  //   if (!params || !params.suppressFocus) {
  //     // Focus the input element for keyboard navigation.
  //     // Can't do this in an effect,
  //     // as the component is not recreated when hidden and then shown again
  //     refInput.current?.focus();
  //   }
  // }, []);

  // register filter handlers with the grid
  useGridFilter({
    doesFilterPass,
    // afterGuiAttached,
  });

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        gap: '10px',
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography
            label="From: "
            variant="caption1"
            color={theme.palette.grey[900]}
          />
          <DateTimeField
            value={fromDate}
            onChange={(newValue) => {
              setFromDate(newValue);
            }}
            format="dd/MM/yyyy HH:mm"
            sx={{ '.MuiInputBase-root': { height: '35px' } }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography
            label="To: "
            variant="caption1"
            color={theme.palette.grey[900]}
          />
          <DateTimeField
            value={toDate}
            onChange={(newValue) => {
              setToDate(newValue);
            }}
            format="dd/MM/yyyy HH:mm"
            sx={{ '.MuiInputBase-root': { height: '35px' } }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <Button
          variant="contained"
          onClick={() => onModelChange(!model)}
          backgroundColor={theme.palette.primaryColor[400]}
        >
          <Typography
            label="Apply"
            variant="body1"
            color={theme.palette.grey.A100}
          />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onModelChange(null);
            setFromDate(null);
            setToDate(null);
          }}
          backgroundColor={theme.palette.warningColor[300]}
        >
          <Typography
            label="Reset"
            variant="body1"
            color={theme.palette.grey.A100}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateTimeFilter;
