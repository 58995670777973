import React, { useContext, useEffect, useState } from 'react';
import { DialogContext, EmployeesContext, LoadingContext } from '../../../App';
import {
  getEmployeeData,
  getNewSoftware,
  getSyncTime,
  syncEmployeeAndComputerData,
} from '../../../services/uem';
import { CircularProgress, Grid } from '@mui/material';
import Textfield from '../../atoms/Textfield';
import Button from '../../atoms/Button';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import EmployeesTable from '../EmployeesTable';
import { TEMP_EMP } from '../../../utils/constants';

const NewHome = () => {
  const { employees, setEmployees } = useContext(EmployeesContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setDialog } = useContext(DialogContext);

  const [syncTime, setSyncTime] = useState<string | null>(null);
  const [softwareName, setSoftwareName] = useState<string>('');

  const getData = async () => {
    setIsLoading(true);
    const emp = await getEmployeeData();
    if (emp) {
      setEmployees(emp);
    } else {
      setDialog({
        title: 'API Error',
        message: 'An error occured while loading Employees',
        error: true,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const lastSyncString = getSyncTime();
    if (lastSyncString) {
      const date = new Date(Number(lastSyncString));
      setSyncTime(`${date.toLocaleString()}`);
    }
  }, [employees]);

  const handleClick = async () => {
    if (softwareName !== '') {
      setIsLoading(true);
      const emp = await getNewSoftware(softwareName);
      if (emp) {
        setEmployees(emp);
      } else {
        setDialog({
          title: 'API Error',
          message: 'An error occured while loading new Software Data',
          error: true,
        });
      }
      setIsLoading(false);
    }
  };

  const handleSoftwareNameChange = (e: {
    target: { name: any; value: string };
  }) => {
    setSoftwareName(e.target.value);
  };

  const handleSyncEmployeesAndComputers = async () => {
    setIsLoading(true);
    const emp = await syncEmployeeAndComputerData();
    if (emp) {
      setEmployees(emp);
    } else {
      setDialog({
        title: 'API Error',
        message: 'An error occured while syncing employees and computers data',
        error: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {isLoading ? (
        <Grid
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            sx={{ display: 'flex', alignItems: 'center', paddingBlock: '10px' }}
          >
            <Grid sx={{ display: 'flex', height: '40px', gap: '5px' }}>
              <Button
                variant="contained"
                onClick={handleSyncEmployeesAndComputers}
                backgroundColor={theme.palette.grey.A100}
                borderColor={theme.palette.grey[200]}
                boxShadowColor={theme.palette.primaryColor[200]}
              >
                <Typography
                  label="Sync Data"
                  variant="body1"
                  color={theme.palette.text.high}
                />
              </Button>
            </Grid>
            {/* <Grid sx={{ display: 'flex', height: '40px', gap: '5px' }}>
              <Textfield
                variant="outlined"
                placeHolderContent="Enter Software Name"
                inputValue={softwareName}
                onChangeMethod={handleSoftwareNameChange}
              />
              <Button
                variant="contained"
                onClick={handleClick}
                backgroundColor={theme.palette.primaryColor[500]}
              >
                <Typography
                  label="Search"
                  variant="body1"
                  color={theme.palette.grey.A100}
                />
              </Button>
            </Grid> */}
            <Grid sx={{ flexGrow: 1 }} />
            <Typography
              label={`Last Sync from API - ${
                syncTime ? `${syncTime} IST` : 'NA'
              }`}
              variant="body1"
              color={theme.palette.grey.A200}
            />
          </Grid>

          <Grid sx={{ width: '100%', flexGrow: 1, display: 'flex' }}>
            <EmployeesTable employees={employees} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default NewHome;
