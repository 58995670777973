import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import React from 'react';

interface ButtonProps extends MuiButtonProps {
  backgroundColor: string | React.CSSProperties;
  borderColor?: string | React.CSSProperties;
  boxShadowColor?: string | React.CSSProperties;
}

const Button = ({
  variant,
  children,
  backgroundColor,
  borderColor,
  boxShadowColor,
  ...props
}: ButtonProps) => {
  return (
    <MuiButton
      variant={variant}
      {...props}
      sx={{
        ...props.sx,
        textTransform: 'none',
        backgroundColor: backgroundColor,
        '&:hover': {
          backgroundColor: backgroundColor,
          borderColor: borderColor || backgroundColor,
          boxShadow: boxShadowColor
            ? `0px 2px 4px -1px ${boxShadowColor}`
            : '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        borderColor: borderColor || backgroundColor,
      }}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
