import {
  AppBar,
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import Button from '../../atoms/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { getGoogleUser, logout } from '../../../services/auth';
import {
  AuthContext,
  DialogContext,
  EmployeesContext,
  LoadingContext,
  ViewsContext,
} from '../../../App';
import MenuIcon from '@mui/icons-material/Menu';
import { getViews } from '../../../services/uem';

interface NavbarProps {
  activeTab?: string;
}

const Navbar = ({ activeTab }: NavbarProps) => {
  const {
    googleAuth,
    setGoogleAuth,
    zohoAuth,
    setZohoAuth,
    googleUser,
    setGoogleUser,
  } = useContext(AuthContext);
  const { employees, setEmployees } = useContext(EmployeesContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setDialog } = useContext(DialogContext);
  const { views, setViews } = useContext(ViewsContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const goToPage = (route: string) => {
    navigate(route);
  };

  const handleLoginOrLogout = async () => {
    if (!(googleAuth && zohoAuth)) {
      navigate('/');
    } else {
      await logout();
      setGoogleAuth(false);
      setZohoAuth(false);
    }
    handleCloseUserMenu();
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getGoogleUserDetails = async () => {
    if (googleAuth) {
      const user = await getGoogleUser();
      if (user) {
        setGoogleUser({
          email: user?.email,
          picture: user?.picture ?? null,
          name: user?.name ?? null,
        });
      }
    }
  };

  const getViewDetails = async () => {
    if (zohoAuth) {
      const tempViews = await getViews();
      if (tempViews) {
        setViews(tempViews);
      }
    }
  };

  useEffect(() => {
    getGoogleUserDetails();
    // getViewDetails();
  }, []);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.grey.A100,
        boxShadow: `0px 2px 4px -1px ${theme.palette.primaryColor[500]}`,
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Grid onClick={() => goToPage('/home')}>
            <Typography
              variant="h6"
              color={
                activeTab !== 'Views'
                  ? theme.palette.primaryColor[200]
                  : theme.palette.text.high
              }
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                textDecoration: 'none',
                cursor: 'pointer',
              }}
              label="Z-GUEMSync"
            />
          </Grid>

          {googleAuth && zohoAuth ? (
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {/* <MenuItem
                  key="Patches"
                  onClick={() => {
                    handleCloseNavMenu();
                    goToPage('/patches');
                  }}
                >
                  <Typography
                    label="Patches"
                    variant="body1"
                    color={theme.palette.text.high}
                  />
                </MenuItem> */}
                <MenuItem
                  key="Views"
                  onClick={() => {
                    handleCloseNavMenu();
                    goToPage('/views');
                  }}
                >
                  <Typography
                    label="Views"
                    variant="body1"
                    color={theme.palette.text.high}
                  />
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <></>
          )}
          <Grid
            onClick={() => goToPage('/home')}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
              label="Z-GUEMSync"
              color={theme.palette.text.high}
            />
          </Grid>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
            }}
          >
            {googleAuth && zohoAuth && (
              <>
                {/* <Button
                  key="Patches"
                  onClick={() => {
                    handleCloseNavMenu();
                    goToPage('/patches');
                  }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  backgroundColor={theme.palette.primaryColor[500]}
                >
                  <Typography
                    label="Patches"
                    variant="subtitle1"
                    color={theme.palette.grey.A100}
                    sx={{
                      textDecoration:
                        activeTab === 'Patches' ? 'underline' : 'none',
                    }}
                  />
                </Button> */}
                <Button
                  variant="outlined"
                  key="Views"
                  onClick={() => {
                    handleCloseNavMenu();
                    goToPage('/views');
                  }}
                  // sx={{ my: 2, color: 'white', display: 'block' }}
                  backgroundColor={theme.palette.grey.A100}
                  borderColor={theme.palette.grey.A100}
                  boxShadowColor={theme.palette.primaryColor[200]}
                >
                  <Typography
                    label="Views"
                    variant="subtitle1"
                    color={
                      activeTab === 'Views'
                        ? theme.palette.primaryColor[200]
                        : theme.palette.text.high
                    }
                  />
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={googleUser?.email[0]} src={googleUser?.picture} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="Google Email" onClick={handleCloseUserMenu}>
                <Typography
                  label={`Google Email: ${googleUser?.email}`}
                  variant="body1"
                  color={theme.palette.text.high}
                />
              </MenuItem>
              <MenuItem key="Logout" onClick={handleLoginOrLogout}>
                <Typography
                  label={googleAuth && zohoAuth ? 'Logout' : 'Login'}
                  variant="body1"
                  color={theme.palette.text.high}
                />
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
