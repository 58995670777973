import React from 'react';
import { Grid } from '@mui/material';
import HomeTemplate from '../../components/templates/HomeTemplate';
import Home from '../../components/organisms/Home';
import NewHome from '../../components/organisms/NewHome';

const HomePage = () => {
  return (
    <HomeTemplate>
      <Grid
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <NewHome />
      </Grid>
    </HomeTemplate>
  );
};

export default HomePage;
