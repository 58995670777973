import React from 'react';
import { Grid } from '@mui/material';

import Login from '../../components/organisms/Login';

const LoginPage = () => {
  return (
    <Grid
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Login />
    </Grid>
  );
};

export default LoginPage;
