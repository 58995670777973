import axios from 'axios';
import {
  BASE_API_URL,
  GOOGLE_ACCESS_TOKEN,
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
  GOOGLE_REFRESH_TOKEN,
  REDIRECT_URI,
  ZOHO_ACCESS_TOKEN,
  ZOHO_REFRESH_TOKEN,
} from '../utils/constants';

export const authenticateZoho = async (search: string) => {
  if (search) {
    const params = search.split('&');
    const codeString = params.filter((p) => p.includes('code'));
    const code = codeString[0].split('=')[1];
    const response = await axios.post(
      `${BASE_API_URL}/auth/token?code=${code}`
    );
    if (response.data?.access_token) {
      const tokenData: any = {
        access_token: response.data.access_token,
        expires_in: response.data.expires_in,
      };
      tokenData.expirationEpoch = Date.now() + tokenData.expires_in * 1000;
      sessionStorage.setItem(ZOHO_ACCESS_TOKEN, JSON.stringify(tokenData));
      localStorage.setItem(ZOHO_REFRESH_TOKEN, response.data.refresh_token);
      return true;
    }
  }
  return false;
};

export const authenticateGoogle = async (search: string) => {
  if (search) {
    const params = search.split('&');
    const codeString = params.filter((p) => p.includes('code'));
    const code = codeString[0].split('=')[1];
    const url = `https://oauth2.googleapis.com/token?grant_type=authorization_code&client_id=${GOOGLE_CLIENT_ID}&client_secret=${GOOGLE_CLIENT_SECRET}&redirect_uri=${REDIRECT_URI}&code=${code}&access_type=offline`;
    const response = await axios.post(url);
    const token = response.data?.access_token;
    const tokenData: any = {
      access_token: response.data.access_token,
      expires_in: response.data.expires_in,
    };
    tokenData.expirationEpoch = Date.now() + tokenData.expires_in * 1000;
    sessionStorage.setItem(GOOGLE_ACCESS_TOKEN, JSON.stringify(tokenData));
    localStorage.setItem(GOOGLE_REFRESH_TOKEN, response.data.refresh_token);
    if (token) {
      return true;
    }
  }
  return false;
};

export const getGoogleAccessToken = () => {
  const googleTokenString = sessionStorage.getItem(GOOGLE_ACCESS_TOKEN);
  if (googleTokenString) {
    const googleAuth = JSON.parse(googleTokenString);
    const diff = googleAuth.expirationEpoch - Date.now();
    if (diff > 0) {
      return googleAuth;
    }
  }
  return null;
};

export const getZohoAccessToken = () => {
  const zohoTokenString = sessionStorage.getItem(ZOHO_ACCESS_TOKEN);
  if (zohoTokenString) {
    const zohoAuth = JSON.parse(zohoTokenString);
    const diff = zohoAuth.expirationEpoch - Date.now();
    if (diff > 0) {
      return zohoAuth;
    }
  }
  return null;
};

export const checkZohoAuth = async () => {
  const zohoAuth = getZohoAccessToken();
  if (zohoAuth) {
    return true;
  } else {
    const res = await refreshZohoAccessToken();
    return res;
  }
};

export const checkGoogleAuth = async () => {
  const googleAuth = getGoogleAccessToken();
  if (googleAuth) {
    return true;
  } else {
    const res = await refreshGoogleAccessToken();
    return res;
  }
};

export const refreshGoogleAccessToken = async () => {
  const refreshTokenString = localStorage.getItem(GOOGLE_REFRESH_TOKEN);
  if (refreshTokenString) {
    const url = `https://oauth2.googleapis.com/token?grant_type=refresh_token&client_id=${GOOGLE_CLIENT_ID}&client_secret=${GOOGLE_CLIENT_SECRET}&refresh_token=${refreshTokenString}`;
    const response = await axios.post(url).catch((err) => console.log(err));
    if (response?.data?.access_token) {
      const tokenData: any = {
        access_token: response.data.access_token,
        expires_in: response.data.expires_in,
      };
      tokenData.expirationEpoch = Date.now() + tokenData.expires_in * 1000;
      sessionStorage.setItem(GOOGLE_ACCESS_TOKEN, JSON.stringify(tokenData));
      return true;
    }
  }
  return false;
};

export const refreshZohoAccessToken = async () => {
  const refreshTokenString = localStorage.getItem(ZOHO_REFRESH_TOKEN);
  if (refreshTokenString) {
    const response = await axios
      .post(
        `${BASE_API_URL}/auth/refresh-token?refresh_token=${refreshTokenString}`
      )
      .catch((err) => console.log(err));
    if (response?.data?.access_token) {
      const tokenData: any = {
        access_token: response.data.access_token,
        expires_in: response.data.expires_in,
      };
      tokenData.expirationEpoch = Date.now() + tokenData.expires_in * 1000;
      sessionStorage.setItem(ZOHO_ACCESS_TOKEN, JSON.stringify(tokenData));
      return true;
    }
  }
  return false;
};

export const logout = async () => {
  const refreshToken = localStorage.getItem(GOOGLE_REFRESH_TOKEN);
  await axios.post(
    `https://oauth2.googleapis.com/revoke?token=${refreshToken}`
  );
  localStorage.removeItem(GOOGLE_REFRESH_TOKEN);
  localStorage.removeItem(ZOHO_REFRESH_TOKEN);
  sessionStorage.removeItem(GOOGLE_ACCESS_TOKEN);
  sessionStorage.removeItem(ZOHO_ACCESS_TOKEN);
};

export const getGoogleUser = async () => {
  let user: any = null;
  try {
    const googleAuth = getGoogleAccessToken();
    if (googleAuth) {
      const response = await axios.get(
        'https://www.googleapis.com/oauth2/v1/userinfo',
        { headers: { Authorization: `Bearer ${googleAuth.access_token}` } }
      );
      if (response.data?.email) {
        user = response.data;
      }
    }
  } catch (err) {
    console.log("Error getting user's information: ");
    console.log(err);
  }
  return user;
};
