import {
  Typography as MuiTypography,
  SxProps,
  Theme,
  TypographyTypeMap,
} from '@mui/material';
import React from 'react';

interface TypographyProps {
  variant: TypographyTypeMap['props']['variant'];
  label: string;
  color: any;
  sx?: SxProps<Theme>;
  className?: string;
}

const Typography = ({
  variant,
  label,
  sx,
  color,
  className,
}: TypographyProps) => {
  return (
    <MuiTypography
      variant={variant}
      color={color}
      sx={sx}
      className={className}
    >
      {label}
    </MuiTypography>
  );
};

export default Typography;
