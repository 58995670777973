import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import { checkGoogleAuth, checkZohoAuth } from './services/auth';
import {
  Dialog,
  DialogProps,
  Employee,
  EmployeeProps,
  GoogleUser,
  LoadingProps,
  LoginPageProps,
  View,
  ViewProps,
} from './utils/types';
import PatchPage from './pages/PatchPage';
import CustomViewFormPage from './pages/CustomViewFormPage';
import CustomViewPage from './pages/CustomViewPage';
import ViewListPage from './pages/ViewListPage';

export const AuthContext = createContext<LoginPageProps>({} as LoginPageProps);
export const EmployeesContext = createContext<EmployeeProps>(
  {} as EmployeeProps
);
export const LoadingContext = createContext<LoadingProps>({} as LoadingProps);
export const DialogContext = createContext<DialogProps>({} as DialogProps);
export const ViewsContext = createContext<ViewProps>({} as ViewProps);

const App = () => {
  const [googleAuth, setGoogleAuth] = useState<boolean | null>(null);
  const [googleUser, setGoogleUser] = useState<GoogleUser | null>(null);
  const [zohoAuth, setZohoAuth] = useState<boolean | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialog, setDialog] = useState<Dialog | null>(null);
  const [views, setViews] = useState<View[]>([]);

  const navigate = useNavigate();

  const checkAuth = async () => {
    const auth1 = await checkGoogleAuth();
    setGoogleAuth(auth1);
    const auth2 = await checkZohoAuth();
    setZohoAuth(auth2);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (googleAuth === false || zohoAuth === false) {
      navigate('/');
    }
  }, [googleAuth, zohoAuth]);

  const authContextValue = useMemo(
    () => ({
      googleAuth,
      setGoogleAuth,
      zohoAuth,
      setZohoAuth,
      googleUser,
      setGoogleUser,
    }),
    [
      googleAuth,
      setGoogleAuth,
      zohoAuth,
      setZohoAuth,
      googleUser,
      setGoogleUser,
    ]
  );
  const employeesContextValue = useMemo(
    () => ({ employees, setEmployees }),
    [employees, setEmployees]
  );
  const viewsContextValue = useMemo(
    () => ({ views, setViews }),
    [views, setViews]
  );
  const loadingContextValue = useMemo(
    () => ({ isLoading, setIsLoading }),
    [isLoading, setIsLoading]
  );
  const dialogContextValue = useMemo(
    () => ({ dialog, setDialog }),
    [dialog, setDialog]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      <EmployeesContext.Provider value={employeesContextValue}>
        <ViewsContext.Provider value={viewsContextValue}>
          <LoadingContext.Provider value={loadingContextValue}>
            <DialogContext.Provider value={dialogContextValue}>
              {googleAuth !== null && zohoAuth !== null && (
                <Routes>
                  <Route path="/" element={<LoginPage />} />
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/patches" element={<PatchPage />} />
                  <Route path="/views" element={<ViewListPage />} />
                  <Route path="/views/:viewId" element={<CustomViewPage />} />
                  <Route path="/views/new" element={<CustomViewFormPage />} />
                  <Route
                    path="/views/:viewId/edit"
                    element={<CustomViewFormPage />}
                  />
                </Routes>
              )}
            </DialogContext.Provider>
          </LoadingContext.Provider>
        </ViewsContext.Provider>
      </EmployeesContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
