import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../organisms/Navbar';
import theme from '../../../theme';
import Button from '../../atoms/Button';
import Typography from '../../atoms/Typography';
import { DialogContext } from '../../../App';
import { Link } from 'react-router-dom';

export interface HomeTemplateProps {
  activeTab?: string;
  children: React.ReactNode;
}

const HomeTemplate = ({ activeTab, children }: HomeTemplateProps) => {
  const { dialog, setDialog } = useContext(DialogContext);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setDialog(null);
  };

  useEffect(() => {
    if (dialog && dialog.title) {
      setOpen(true);
    }
  }, [dialog]);

  return (
    <>
      <Grid
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '10px',
          minWidth: '600px',
        }}
      >
        <Grid
          sx={{
            width: 'calc(100% - 20px)',
            margin: '10px',
            height: '70px',
            position: 'fixed',
            top: 0,
            minWidth: 'inherit',
          }}
        >
          <Navbar activeTab={activeTab} />
        </Grid>
        <Grid
          sx={{
            width: '100%',
            height: '100%',
            marginTop: '85px',
            overflow: 'hidden',
          }}
        >
          {children}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography
            label={dialog?.title ?? ''}
            variant="body1"
            color={
              dialog?.error
                ? theme.palette.errorColor[500]
                : theme.palette.primaryColor[500]
            }
          />
        </DialogTitle>
        <DialogContent>
          <Typography
            label={dialog?.message ?? ''}
            variant="body1"
            color={theme.palette.grey.A200}
          />
          {dialog?.url && (
            <Link to={dialog.url} title="Google Sheet" target="_blank">
              Google Sheet
            </Link>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            backgroundColor={theme.palette.grey.A100}
            borderColor={theme.palette.grey[200]}
            boxShadowColor={theme.palette.primaryColor[200]}
          >
            <Typography
              label="Ok"
              variant="body1"
              color={theme.palette.text.high}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HomeTemplate;
