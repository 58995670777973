import React from 'react';
import { Color, createTheme } from '@mui/material';
import {
  PaletteColor,
  PaletteColorOptions,
} from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  interface TypeText {
    low: string;
    medium: string;
    high: string;
  }

  interface CustomPalette {
    primary: Color;
    text: TypeText;
    grey: Color;
    success: Color;
    warning: Color;
    error: Color;
  }

  interface Palette extends CustomPalette {
    primaryColor: Color;
    text: TypeText;
    grey: Color;
    successColor: Color;
    warningColor: Color;
    errorColor: Color;
  }

  interface PaletteOptions extends CustomPalette {
    primaryColor?: Partial<Color>;
    text?: Partial<TypeText>;
    grey?: Partial<Color>;
    successColor?: Partial<Color>;
    warningColor?: Partial<Color>;
    errorColor?: Partial<Color>;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption1: true;
    caption2: true;
    h1: false;
    h2: false;
    h3: false;
    h5: false;
    caption: false;
  }
}

const theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: ['Graphik', 'sans-serif'].join(','),
    h4: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '60px',
      lineHeight: '54px',
      letterSpacing: '-0.01em',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '34px',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0.005em',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0.005em',
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.01em',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.01em',
    },
    button: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '42px',
      letterSpacing: '0.01em',
      textTransform: 'none',
    },
    caption1: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.01em',
    },
    caption2: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.01em',
    },
    overline: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.005em',
      textTransform: 'none',
    },
  },
  palette: {
    primaryColor: {
      100: '#FAFCFF',
      200: '#00ADB5',
      300: '#CCE3FF',
      400: '#1976d2',
      500: '#222831',
      700: '#002EB7',
      900: '#00177A',
    },
    text: {
      low: '#B2B2B9',
      medium: '#7D7D89',
      high: '#343446',
    },
    grey: {
      50: '#F2F2F7',
      100: '#E8E8F7',
      200: '#EEEEEE',
      300: '#B4B4CF',
      400: '#667085',
      500: '#4B4B60',
      600: '#344054',
      700: '#252545',
      900: '#0E0E2E',
      A100: '#FFFFFF',
      A200: '#000000',
    },
    successColor: {
      100: '#E9F7EC',
      500: '#20B03F',
    },
    warningColor: {
      100: '#FFF6ED',
      300: '#FFA74F',
      500: '#F7931A',
    },
    errorColor: {
      100: '#F3E6EB',
      500: '#B71A33',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
