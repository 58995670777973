export const GOOGLE_ACCESS_TOKEN = 'GOOGLE_ACCESS_TOKEN';
export const ZOHO_ACCESS_TOKEN = 'ZOHO_ACCESS_TOKEN';
export const GOOGLE_REFRESH_TOKEN = 'GOOGLE_REFRESH_TOKEN';
export const ZOHO_REFRESH_TOKEN = 'ZOHO_REFRESH_TOKEN';
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET;
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const REDIRECT_URI = process.env.REDIRECT_URI;
export const GOOGLE_SCOPE =
  'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/admin.directory.user.readonly';
export const ZOHO_SCOPE =
  'DesktopCentralCloud.PatchMgmt.READ,DesktopCentralCloud.Inventory.READ,DesktopCentralCloud.Common.READ';
export const ZOHO_CLIENT_ID = process.env.ZOHO_CLIENT_ID;
export const BASE_API_URL = process.env.BASE_API_URL;
export const LAST_SYNC = 'LAST_SYNC';

export const TEMP_EMP = [
  {
    _id: '65f2b8799e5140424e1b1878',
    empId: 'ZTPL0962',
    firstName: 'Kritika',
    lastName: 'Depura',
    email: 'kritika.depura@zemosolabs.com',
    department: 'Engineering',
    designation: 'Senior Software Engineer III',
    managerEmail: 'manu.g@zemosolabs.com',
    employeeType: 'FTE',
    ownerEmail: 'kritika.depura@zemosolabs.com',
    serialNumber: 'PF3JTN67',
    computerId: '10276000000251011',
    userName: 'zadmin',
    osPlatformName: 'Linux',
    osName: 'Ubuntu 22.04.3 LTS (Jammy Jellyfish)',
    computerLiveStatus: 2,
    lastBootupTime: 1707378300000,
  },
  {
    _id: '65f2b8799e5140424e1b1879',
    empId: 'ZTPL0544',
    firstName: 'Aishwarya Gopikrishna',
    lastName: 'Kulkarni',
    email: 'aishwarya.kulkarni@zemosolabs.com',
    department: 'Engineering',
    designation: 'Software Engineer III',
    managerEmail: 'sudhir@zemosolabs.com',
    employeeType: 'FTE',
    ownerEmail: 'aishwarya.kulkarni@zemosolabs.com',
    serialNumber: '5CG0275JHJ',
    computerId: '10276000000267055',
    userName: 'aishwk',
    osPlatformName: 'Linux',
    osName: 'Ubuntu 20.04.6 LTS (Focal Fossa)',
    computerLiveStatus: 1,
    lastBootupTime: 1710394380000,
  },
  {
    _id: '65f2b8799e5140424e1b187a',
    empId: 'ZTPL0665',
    firstName: 'Sowmya',
    lastName: 'Gadireddy',
    email: 'sowmya.gadireddy@zemosolabs.com',
    department: 'Engineering',
    designation: 'Software Engineer II',
    managerEmail: 'nabanita.sinha@zemosolabs.com',
    employeeType: 'FTE',
    ownerEmail: 'sowmya.gadireddy@zemosolabs.com',
    serialNumber: 'GPQZRK3',
    computerId: '10276000000267089',
    userName: 'sowmga',
    osPlatformName: 'Linux',
    osName: 'Ubuntu 20.04.6 LTS (Focal Fossa)',
    computerLiveStatus: 1,
    lastBootupTime: 1710133860000,
  },
  {
    _id: '65f2b8799e5140424e1b187b',
    empId: 'ZTPL0749',
    firstName: 'Vikram Ankush',
    lastName: 'Padwal',
    email: 'vikram.padwal@zemosolabs.com',
    department: 'Engineering',
    designation: 'Senior Software Engineer II',
    managerEmail: 'vijay@zemosolabs.com',
    employeeType: 'FTE',
    ownerEmail: 'vikram.padwal@zemosolabs.com',
    serialNumber: 'PF3JTDLR',
    computerId: '10276000000267163',
    userName: 'vikp',
    osPlatformName: 'Linux',
    osName: 'Ubuntu 22.04.3 LTS (Jammy Jellyfish)',
    computerLiveStatus: 2,
    lastBootupTime: 1709654580000,
  },
  {
    _id: '65f2b8799e5140424e1b187c',
    empId: 'ZTPL0462',
    firstName: 'Thrisha',
    lastName: 'Bandi',
    email: 'thrisha.bandi@zemosolabs.com',
    department: 'Engineering',
    designation: 'Senior Software Engineer I',
    managerEmail: 'nabanita.sinha@zemosolabs.com',
    employeeType: 'FTE',
    ownerEmail: 'thrisha.bandi@zemosolabs.com',
    serialNumber: 'BL06W93',
    computerId: '10276000000267193',
    userName: 'thrisb',
    osPlatformName: 'Linux',
    osName: 'Ubuntu 20.04.6 LTS (Focal Fossa)',
    computerLiveStatus: 1,
    lastBootupTime: 1710394620000,
  },
  {
    _id: '65f2b8799e5140424e1b187d',
    empId: 'ZTPL0597',
    firstName: 'Prem Sai',
    lastName: 'Tippaluri',
    email: 'premsai.tippaluri@zemosolabs.com',
    department: 'QA',
    designation: 'Software Development Engineer In Test II',
    managerEmail: 'shwetha.palleti@zemosolabs.com',
    employeeType: 'FTE',
    ownerEmail: 'premsai.tippaluri@zemosolabs.com',
    serialNumber: 'DPKGXF3',
    computerId: '10276000000267203',
    userName: 'premst',
    osPlatformName: 'Linux',
    osName: 'Ubuntu 20.04.6 LTS (Focal Fossa)',
    computerLiveStatus: 1,
    lastBootupTime: 1710337620000,
  },
  {
    _id: '65f2b8799e5140424e1b187e',
    empId: 'ZTPL0860',
    firstName: 'Gajesingh',
    lastName: 'Bhati',
    email: 'gajesingh.bhati@zemosolabs.com',
    department: 'Engineering',
    designation: 'Software Engineer III',
    managerEmail: 'velashanbhag.desai@zemosolabs.com',
    employeeType: 'FTE',
    ownerEmail: 'gajesingh.bhati@zemosolabs.com',
    serialNumber: 'MP29E629',
    computerId: '10276000000267285',
    userName: 'gajeb',
    osPlatformName: 'Linux',
    osName: 'Ubuntu 20.04.6 LTS (Focal Fossa)',
    computerLiveStatus: 2,
    lastBootupTime: 1709472420000,
  },
  {
    _id: '65f2b8799e5140424e1b187f',
    empId: 'ZTPL0631',
    firstName: 'Malyadri Mekapati',
    lastName: 'Naidu',
    email: 'malyadri.mekapati@zemosolabs.com',
    department: 'DevOps',
    designation: 'DevOps Engineer II',
    managerEmail: 'sudipta.ojha@zemosolabs.com',
    employeeType: 'FTE',
    ownerEmail: 'malyadri.mekapati@zemosolabs.com',
    serialNumber: 'PF3JL71R',
    computerId: '10276000000267289',
    userName: 'malyadm',
    osPlatformName: 'Linux',
    osName: 'Ubuntu 20.04.6 LTS (Focal Fossa)',
    computerLiveStatus: 1,
    lastBootupTime: 1710401460000,
  },
];
